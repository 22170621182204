<template>
  <!-- First Row: Status and Best Difficulty -->
  <div class="flex justify-between items-center mb-0">
    <h3 class="text-sm">
      Status: <span :class="statusFlashClass">{{ formattedStatus }}</span>
    </h3>
    <h3 class="text-sm w-60 flex justify-end">
      Best Difficulty:
      <span class="ml-2" :class="flashClass">{{ currentBestDifficulty }}</span>
    </h3>
  </div>

  <div class="relative bg-base-200 rounded-xl shadow-md px-6 pt-4 pb-0 mb-4">
    <!-- Progress bar background -->
    <div
      class="absolute inset-0 z-0 bg-primary rounded-xl"
      :style="{ width: `${progressPercentage}%` }"
    ></div>

    <!-- Overlay Content -->
    <div class="relative z-10 text-base-content">
      <!-- Display waiting status if waiting, otherwise show nonce details -->
      <div v-if="!waitStatus" class="flex justify-between text-sm mb-0 pb-0">
        <p>Solutions: {{ noncesChecked }} / {{ totalNonces }}</p>
        <p>Nonces/sec: {{ noncesPerSecond }}</p>
        <p>Challenge: {{ abbreviatedChallenge }}</p>
      </div>
      <div v-else class="flex justify-between text-sm mb-0 pb-0">
        <p>Waiting {{ formattedCountdown }} until next check-in time</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch, onUnmounted } from "vue";

// Define props with default values
const props = defineProps({
  status: {
    type: String,
    default: "Idle",
  },
  noncesChecked: {
    type: Number,
    default: 0,
  },
  noncesPerSecond: {
    type: Number,
    default: 0,
  },
  noncesRemaining: {
    type: Number,
    default: 0,
  },
  challenge: {
    type: String,
    default: "",
  },
  nonceRangeStart: {
    type: Number,
    default: 0,
  },
  nonceRangeEnd: {
    type: Number,
    default: 0,
  },
  currentBestDifficulty: {
    type: Number,
    default: 0,
  },
  totalSolvingTime: {
    type: Number,
    default: 0,
  },
  timeRemaining: {
    type: Number,
    default: 0,
  },
  timeElapsed: {
    type: Number,
    default: 0,
  },
});

// Flash class for best difficulty
const flashClass = ref("");
const statusFlashClass = ref("");

// Function to trigger the flash effect
const triggerFlash = () => {
  flashClass.value = "text-secondary"; // Add the primary color class
  setTimeout(() => {
    flashClass.value = ""; // Remove the class after 500ms
  }, 2500);
};
const triggerStatusFlash = () => {
  statusFlashClass.value = "text-secondary"; // Add the primary color class
  setTimeout(() => {
    statusFlashClass.value = ""; // Remove the class after 500ms
  }, 500);
};

// Watch for changes in currentBestDifficulty and trigger the flash
watch(
  () => props.currentBestDifficulty,
  (newDifficulty, oldDifficulty) => {
    if (newDifficulty !== oldDifficulty) {
      triggerFlash();
    }
  }
);

watch(
  () => props.status,
  (newStatus, oldStatus) => {
    if (newStatus !== oldStatus) {
      triggerStatusFlash();
    }
  }
);

// Reactive state for wait status and countdown
const waitStatus = ref(false);
const countdown = ref(0);

// Computed property for total nonces and progress percentage fixing loading value issue
const totalNonces = computed(() => {
  const total = props.nonceRangeEnd - props.nonceRangeStart;
  if (total === 0) {
    return 0;
  } else {
    return total + 1;
  }
});

// Update progressPercentage to **always** prioritize timeRemaining by default
const progressPercentage = computed(() => {
  if (props.totalSolvingTime === 0 || !props.totalSolvingTime) {
    return 0;
  } else {
    let barCompletionPercent =
      (props.timeElapsed / props.totalSolvingTime) * 100;
    if ((props.timeElapsed / props.totalSolvingTime) * 100 > 97) {
      barCompletionPercent = Math.ceil(
        (props.timeElapsed / props.totalSolvingTime) * 100
      );
    }
    return Math.min(barCompletionPercent, 100);
  }
});

// Function to abbreviate the challenge string
const abbreviatedChallenge = computed(() => {
  if (props.challenge.length <= 10) return props.challenge;
  return `${props.challenge.slice(0, 5)}...${props.challenge.slice(-5)}`;
});

const formattedCountdown = computed(() => {
  const waitTime = countdown.value;
  if (waitTime === undefined || waitTime <= 0) return "0h 0m 0s";

  // const hours = Math.floor(waitTime / (60 * 60 * 1000)) || 0;
  const minutes = Math.floor((waitTime % (60 * 60 * 1000)) / (60 * 1000)) || 0;
  const seconds = Math.floor((waitTime % (60 * 1000)) / 1000) || 0;

  return `${minutes}m ${seconds}s`;
});

const formattedStatus = computed(() => {
  if (props.status.includes("Waiting") || props.totalSolvingTime <= 3) {
    return "Waiting...";
  } else {
    return props.status;
  }
});

// Function to format the countdown into h:m:s
// const printCountdown = (waitTime) => {
//   console.log("Wait time", waitTime);
//   const hours = Math.floor(waitTime / (60 * 60 * 1000)) || 0;
//   const minutes = Math.floor((waitTime % (60 * 60 * 1000)) / (60 * 1000)) || 0;
//   const seconds = Math.floor((waitTime % (60 * 1000)) / 1000) || 0;
//   return `${hours}h ${minutes}m ${seconds}s`;
// };

// Countdown timer logic
let countdownInterval = null;

const startCountdown = () => {
  if (countdownInterval) clearInterval(countdownInterval);
  countdownInterval = setInterval(() => {
    if (countdown.value > 0) {
      countdown.value -= 1000; // Decrement by 1 second (1000ms)
    } else {
      clearInterval(countdownInterval);
    }
  }, 1000); // Update every 1 second
};

const stopCountdown = () => {
  if (countdownInterval) clearInterval(countdownInterval);
};

// Watch the status prop to handle waiting status and countdown
watch(
  () => props.status,
  (newStatus) => {
    if (newStatus.includes("Waiting")) {
      console.log("Waiting status detected");
      waitStatus.value = true;

      // Extract remaining time directly from status
      const timeMatch = newStatus.match(/Waiting (\d+) seconds/);
      console.log("Time match:", timeMatch);
      if (timeMatch) {
        countdown.value = parseInt(timeMatch[1], 10) * 1000; // Convert seconds to ms
        console.log("Countdown value:", countdown.value);
      } else {
        countdown.value = props.timeRemaining; // Fallback if status parsing fails
        console.log("Countdown value:", countdown.value);
      }

      startCountdown(); // Start the countdown when in waiting state
    } else {
      waitStatus.value = false;
      stopCountdown(); // Stop countdown if no longer in waiting state
    }
  },
  { immediate: true }
);

// Clean up the countdown timer on unmount
onUnmounted(() => {
  if (countdownInterval) clearInterval(countdownInterval);
});
</script>

<style scoped>
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.z-0 {
  z-index: 0;
}
.z-10 {
  z-index: 10;
}
</style>

import { defineStore } from "pinia";
import { ref } from "vue";
import { startSolving, stopSolving } from "./solver";

export const useSolverStore = defineStore("solving", () => {
  const isSolving = ref(false);
  const currentApiKey = ref("");

  function startSearch(apiKey) {
    console.log("[SolverStore] Starting searching with API key:", apiKey);
    if (!isSolving.value || currentApiKey.value !== apiKey) {
      if (isSolving.value) {
        stopSolving();
      }
      isSolving.value = true;
      currentApiKey.value = apiKey;
      startSolving(apiKey);
    }
  }

  function stopSearch() {
    console.log("[SolverStore] Stopping searching");
    if (isSolving.value) {
      isSolving.value = false;
      currentApiKey.value = "";
      stopSolving();
    }
  }

  function updateApiKey(newApiKey) {
    console.log("[SolverStore] Updating API key:", newApiKey);
    if (isSolving.value && currentApiKey.value !== newApiKey) {
      stopSearch();
      startSearch(newApiKey);
    } else {
      currentApiKey.value = newApiKey;
    }
  }

  return { isSolving, currentApiKey, startSearch, stopSearch, updateApiKey };
});

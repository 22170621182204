import { defineStore } from "pinia";
import axios from "axios";

export const useSiteDataStore = defineStore("siteData", {
  state: () => ({
    siteData: null,
    pagesData: null,
    siteVersion: 0,
    pagesVersion: 0,
  }),
  actions: {
    async fetchSiteData() {
      try {
        // Fetch the current site version from the backend
        const versionResponse = await axios.get(
          `${process.env.VUE_APP_BACKEND_API_URL}/site-version`,
          {
            headers: { "X-API-KEY": process.env.VUE_APP_X_API_KEY },
          }
        );
        const currentVersion = versionResponse.data.version;

        // Only fetch site data if the version has changed
        if (this.siteVersion !== currentVersion) {
          const response = await axios.get(
            `${process.env.VUE_APP_BACKEND_API_URL}/site`,
            {
              headers: { "X-API-KEY": process.env.VUE_APP_X_API_KEY },
            }
          );
          // console.log("Site data fetched:", response.data);

          this.siteData = response.data;
          this.siteVersion = currentVersion;
          localStorage.setItem("siteData", JSON.stringify(this.siteData));
          localStorage.setItem("siteVersion", this.siteVersion.toString());
          console.log("Site data updated to version:", this.siteVersion);
        }
      } catch (error) {
        console.error(
          "Error fetching site data:",
          error.response ? error.response.data : error.message
        );
      }
    },
    async fetchPagesData() {
      try {
        // Fetch the current pages version from the backend
        const versionResponse = await axios.get(
          `${process.env.VUE_APP_BACKEND_API_URL}/pages-version`,
          {
            headers: { "X-API-KEY": process.env.VUE_APP_X_API_KEY },
          }
        );
        const currentVersion = versionResponse.data.version;

        // Only fetch pages data if the version has changed
        if (this.pagesVersion !== currentVersion) {
          const response = await axios.get(
            `${process.env.VUE_APP_BACKEND_API_URL}/pages`,
            {
              headers: { "X-API-KEY": process.env.VUE_APP_X_API_KEY },
            }
          );
          // console.log("Pages data fetched:", response.data);

          this.pagesData = response.data;
          this.pagesVersion = currentVersion;
          localStorage.setItem("pagesData", JSON.stringify(this.pagesData));
          localStorage.setItem("pagesVersion", this.pagesVersion.toString());
          console.log("Pages data updated to version:", this.pagesVersion);
        }
      } catch (error) {
        console.error(
          "Error fetching pages data:",
          error.response ? error.response.data : error.message
        );
      }
    },
    initializeFromLocalStorage() {
      const storedSiteData = localStorage.getItem("siteData");
      const storedPagesData = localStorage.getItem("pagesData");
      const storedSiteVersion = localStorage.getItem("siteVersion");
      const storedPagesVersion = localStorage.getItem("pagesVersion");

      if (storedSiteData && storedSiteData !== "undefined") {
        try {
          this.siteData = JSON.parse(storedSiteData);
        } catch (error) {
          console.error("Error parsing stored site data:", error);
          this.siteData = null;
        }
      }

      if (storedPagesData && storedPagesData !== "undefined") {
        try {
          this.pagesData = JSON.parse(storedPagesData);
        } catch (error) {
          console.error("Error parsing stored pages data:", error);
          this.pagesData = null;
        }
      }

      this.siteVersion =
        storedSiteVersion && storedSiteVersion !== "undefined"
          ? parseInt(storedSiteVersion, 10)
          : 0;
      this.pagesVersion =
        storedPagesVersion && storedPagesVersion !== "undefined"
          ? parseInt(storedPagesVersion, 10)
          : 0;
    },
  },
});

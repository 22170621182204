export function devLog(...args) {
  if (process.env.VUE_APP_ENVIRONMENT === "development") {
    console.log(...args);
  }
}
// Function to check for low performance devices. Skips low performance devices in order to preserve UX.
export async function categorizeDevicePerformance() {
  const userAgent = navigator.userAgent;

  // Check if the user is Googlebot or Bingbot
  if (/Googlebot|bingbot/i.test(userAgent)) {
    return {
      category: 5,
      deviceInfo: {
        isBot: true,
        userAgent,
      },
    };
  }

  const deviceInfo = {
    cores: navigator.hardwareConcurrency || 1, // Default to 1 core if not available
    memory:
      typeof navigator.deviceMemory !== "undefined"
        ? navigator.deviceMemory
        : null, // Skip if not available
    screenSize: {
      width: window.screen.width,
      height: window.screen.height,
    },
    isMobile:
      /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        userAgent
      ),
    connectionType: navigator.connection
      ? navigator.connection.effectiveType
      : "unknown",
    batteryLevel: 1, // Default to full battery
    batteryCharging: true, // Default to charging
  };

  // Fetch additional battery information (if available)
  if (navigator.getBattery) {
    const battery = await navigator.getBattery();
    deviceInfo.batteryLevel = battery.level;
    deviceInfo.batteryCharging = battery.charging;
  }

  // Define helper functions for performance checks
  const highCoresAndMemory = () =>
    deviceInfo.cores >= 8 &&
    (deviceInfo.memory === null || deviceInfo.memory >= 8);

  const midCoresAndMemory = () =>
    deviceInfo.cores >= 4 &&
    (deviceInfo.memory === null || deviceInfo.memory >= 4);

  const lowCoresAndMemory = () =>
    deviceInfo.cores < 4 ||
    (deviceInfo.memory !== null && deviceInfo.memory < 4);

  const fastConnection = () =>
    ["4g", "wifi"].includes(deviceInfo.connectionType) ||
    deviceInfo.connectionType === "unknown"; // Assume unknown connections are fast

  const slowConnection = () =>
    ["2g", "slow-2g"].includes(deviceInfo.connectionType);

  const moderateConnection = () => deviceInfo.connectionType === "3g";

  const isHighEndMobile = () =>
    deviceInfo.isMobile && deviceInfo.cores >= 6 && deviceInfo.memory >= 4;

  const largeScreen = () =>
    deviceInfo.screenSize.width >= 1920 && deviceInfo.screenSize.height >= 1080; // Assume large screens are high-performance desktop or laptop

  // New condition to check battery level and charging status
  const lowBattery = () =>
    deviceInfo.batteryLevel < 0.2 && !deviceInfo.batteryCharging;

  // Categorization logic based on the collected device information
  let category = 0;

  // Check for low battery first
  if (lowBattery()) {
    category = 4; // Set as low-performance if the battery is under 20% and not charging
  } else if (slowConnection()) {
    category = 4; // Very slow connection, lowest priority
  } else if (
    highCoresAndMemory() &&
    fastConnection() &&
    !deviceInfo.isMobile &&
    largeScreen()
  ) {
    category = 0; // High-performance desktop/laptop with large screen and fast connection
  } else if (
    midCoresAndMemory() &&
    fastConnection() &&
    (!deviceInfo.isMobile || isHighEndMobile())
  ) {
    category = 1; // Moderate desktop or high-end mobile
  } else if (lowCoresAndMemory() || moderateConnection()) {
    category = 2; // Low-performance or mid-range mobile/low-end desktop
  } else {
    category = 3; // Very low-performance
  }

  devLog(`[categorizeDevicePerformance] Device category: ${category}`);
  devLog(
    `[categorizeDevicePerformance] Device info: ${JSON.stringify(deviceInfo)}`
  );

  return {
    category,
    deviceInfo,
  };
}

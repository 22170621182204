<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  watch,
  computed,
  nextTick,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { useSessionStore } from "@/stores/session";
import { useSiteDataStore } from "@/stores/siteDataStore";
import AppBar from "@/components/navigation/AppBar";
import FooterBar from "@/components/navigation/FooterBar";
import SideNavigation from "@/components/navigation/SideNavigation";
import { SpeedInsights } from "@vercel/speed-insights/vue";
// import { initializeManager, cleanupManager } from "@/utilities/ore/manager";

const session = useSessionStore();
const siteDataStore = useSiteDataStore();
const isOpen = ref(window.innerWidth > 768);
const isMobile = ref(window.innerWidth <= 768);
const isLoading = ref(true);
const router = useRouter();
const route = useRoute();
const isLoggedIn = computed(() => session.isLoggedIn);
const isSideNavExpanded = computed(() => !session.isSideNavCollapsed);

const isHomePage = computed(() => route.name === "Home");
const gradientClass = computed(() => {
  if (isHomePage.value) {
    return session.theme === "customdark" ? "dark-gradient" : "light-gradient";
  }
  return "";
});

// New function to check and store affiliate parameter
const checkAndStoreAffiliateParam = () => {
  const affiliateParam = router.currentRoute.value.query.aff;
  if (affiliateParam && !localStorage.getItem("affiliate")) {
    try {
      localStorage.setItem("affiliate", affiliateParam);
      // console.log("Affiliate parameter stored:", affiliateParam);
    } catch (error) {
      console.error("Error storing affiliate parameter:", error);
    }
  }
};

// Async setup function to initialize site data
const initializeSiteData = async () => {
  try {
    siteDataStore.initializeFromLocalStorage();
    await siteDataStore.fetchSiteData();
    await new Promise((resolve) => setTimeout(resolve, 10)); // Small delay
    await siteDataStore.fetchPagesData();
  } catch (error) {
    console.error("Failed to initialize site data:", error);
  } finally {
    isLoading.value = false;
  }
};

// Call initializeSiteData immediately
initializeSiteData();

// const toggleNav = () => {
//   isOpen.value = !isOpen.value;
// };

const handleResize = () => {
  isMobile.value = window.innerWidth <= 768;
  isOpen.value = !isMobile.value;
  if (!isMobile.value && isLoggedIn.value) {
    session.setSideNavCollapsed(true);
  }
};

router.beforeEach((to, from, next) => {
  isLoading.value = true;
  next();
});

router.afterEach(() => {
  isLoading.value = false;
});

// Theme handling
const initializeTheme = () => {
  const savedTheme = localStorage.getItem("theme") || "customdark";
  session.setTheme(savedTheme);
  applyTheme(savedTheme);
};

const applyTheme = (theme) => {
  document.documentElement.setAttribute("data-theme", theme);
  document.documentElement.classList.toggle("dark", theme === "customdark");
};

watch(
  () => session.theme,
  (newTheme) => {
    localStorage.setItem("theme", newTheme);
    applyTheme(newTheme);
  }
);

onMounted(() => {
  window.addEventListener("resize", handleResize);
  initializeTheme();
  if (!session.user) {
    session.fetchUser();
  }
  session.setSideNavCollapsed(true);

  // Start the captcha process
  // initializeManager(router);

  // Check for affiliate parameter
  nextTick(() => {
    checkAndStoreAffiliateParam();
  });
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", handleResize);
  // cleanupManager();
});
</script>

<template>
  <div
    v-if="isLoading"
    class="flex justify-center items-center h-screen w-full"
  >
    <span class="loading loading-spinner loading-lg"></span>
  </div>
  <div v-else class="flex flex-col min-h-screen" :data-theme="session.theme">
    <SpeedInsights />
    <!-- AppBar -->
    <app-bar class="block"></app-bar>

    <!-- Main content with conditional SideNavigation -->
    <div class="flex flex-grow relative site-background" :class="gradientClass">
      <SideNavigation v-if="isLoggedIn && !isMobile" />
      <main
        class="flex-grow relative overflow-x-hidden"
        :class="{
          'ml-16': isLoggedIn && !isMobile && !isSideNavExpanded,
          'ml-64': isLoggedIn && !isMobile && isSideNavExpanded,
        }"
      >
        <!-- <button
          class="btn btn-square btn-ghost p-4 fixed top-4 left-4 z-10"
          @click="toggleNav"
          v-if="isMobile"
        >
          <font-awesome-icon icon="bars" />
        </button> -->

        <router-view></router-view>
      </main>
    </div>

    <footer-bar></footer-bar>
  </div>
</template>

<style scoped>
.site-background {
  @apply bg-base-300;
}

/* Dark Theme Gradient */
.dark-gradient {
  background: rgba(0, 0, 0, 1);
  background-image: radial-gradient(
    circle farthest-side at 50% -96%,
    rgba(255, 119, 0, 0.24),
    #cee6f5 0%,
    rgba(125, 129, 168, 0.66) 0%,
    rgba(255, 255, 255, 0)
  );
}

/* Light Theme Gradient */
.light-gradient {
  background: rgba(255, 255, 255, 1);
  background-image: radial-gradient(
    circle farthest-side at 50% -96%,
    rgba(255, 199, 100, 0.24),
    #ffefc3 0%,
    rgba(245, 245, 245, 0.66) 0%,
    rgba(0, 0, 0, 0)
  );
}
.main-content {
  flex-grow: 1;
  transition: margin-left 0.3s ease;
}

.router-view-content {
  padding: 20px 0;
}

@media (min-width: 1200px) {
  .router-view-content {
    padding: 40px 0;
  }
}
</style>
